import React from "react"
import s from "./service.module.scss"
import Layout from "../layout"
import Heading from "../../atoms/Heading"
import Subheading from "../../atoms/Subheading"
import ContentContainer from "../../atoms/ContentContainer"
import Job from "../../molecules/Job"

function Service({ pageContext }) {
    return (
        <Layout>
            <ContentContainer>
                <div className={s.servicesPage}>
                    <Heading>{pageContext.title}</Heading>
                    {pageContext.title === "HVLP Spraying" ? (
                        <div className={s.sprayingIntro}>
                            <div className={s.intro}>
                                <Subheading justified={true}>
                                    {pageContext.description}
                                </Subheading>
                            </div>
                            <div className={s.video}>
                                <iframe
                                    width="560"
                                    height="315"
                                    src="https://www.youtube.com/embed/X9DZaB62fpQ"
                                    frameborder="0"
                                    allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                                    allowfullscreen
                                />
                            </div>
                        </div>
                    ) : (
                        <Subheading>{pageContext.description}</Subheading>
                    )}

                    <hr />
                    {pageContext.portfolio &&
                        pageContext.portfolio.length &&
                        pageContext.portfolio.map((job, index) => (
                            <Job key={`porfolio-item-${index}`} {...job} />
                        ))}
                </div>
            </ContentContainer>
        </Layout>
    )
}

export default Service
